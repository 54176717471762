<template>
  <div> 
    <p class="second-quality-title mb-0 pb-0 d-flex mx-3">
      {{$t("Ekip Sipariş Adeti  ve Fire Oranı") }}
      <button-with-icon
        v-if="resizeBool == null || resizeBool == false"
        icon-name="icons.report.enlarge"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
      <button-with-icon
        v-if="resizeBool == true"
        icon-name="icons.report.reduce"
        class="button-open-close"
        size="sm"
        @onClick="resize()"
      ></button-with-icon>
    </p>
    <div
      class="row loading-area text-center justify-content-center align-items-center"
      v-if="chartLoading"
    >
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div >
      <div
        v-if="!chartLoading"
        v-on:click="resizeBool ? null : resize()"
        :class="{ 'graphic-container': resizeBool }"
        class="mt-0 pt-0"
      >
        <apexchart
          type="bar"
          :height="height"
          :options="chartOptions"
          :series="series"
          :width="tmpWidth"
        ></apexchart>
      </div>
    </div> 
  </div>
</template>
<script>
import { GET_ITEMS } from "@/core/services/store/REST.module";
import VueApexCharts from "vue-apexcharts";
import { mapGetters } from "vuex";
import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
import * as _ from "lodash";
import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
export default {
  name: "FireReportBarChart",
  components: {
    apexchart: VueApexCharts,
    ButtonWithIcon,
  },
  data() {
    return {
      height: 410,
      width: null,
      chartCategories: [], 
      chartItems: [], 
      tmpSeries: [],
      series: [], 
      chartLoading: false,
      resizeBool: false,
      getDataStatus: false,   
      slaughter_fire_ratio:[],
      order_amounts:[], 
      tmpWidth:null
    };
  },
  computed: {
    lineLength() {},
    chartOptions() {
      let seriesLength = this.resizeBool ? 0.75 : 1;
      if (this.resizeBool) {
        this.tmpWidth = window.innerWidth * seriesLength;
      } else {
        let widhMiniScreen = 0;
        if (window.innerWidth <= 1500) {
          widhMiniScreen = 2.8;
        } else {
          widhMiniScreen = 3.1;
        }
        this.tmpWidth = window.innerWidth / widhMiniScreen;
      }
      let self = this;
      let tmpChartOptions = {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          bar: {
            horizontal: true,
            columnWidth:'80%',
            dataLabels: {
              position: "top",
            },
          },
        },  
        stroke: {
          show: true,
          width: 3,
          colors: ['transparent']
        },
        dataLabels: { 
          position: 'top',
          enabled: true,
          offsetX: 20,
          formatter: function (val) { 
            return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        stroke: {
          show: true, 
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,  
          y: {
            formatter: function (val) { 
              return val.toLocaleString("tr-TR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }
          }, 
        },
        xaxis: {
          categories: this.chartCategories,
        },
      };
      return tmpChartOptions;
    },  
  },
  props: [
    "startDate",
    "endDate",
    "currencyRateOptions", 
    "onClickButton",
    "filters",
  ],
  mounted() {
    this.getItems();
    this.updateColors();
  },
  methods: {
    resize() {
      this.resizeBool = !this.resizeBool;
      if (this.resizeBool) {
        this.height = 400;
        this.tmpWidth = window.innerWidth / 2;
        this.$emit("resizecard", "linechart");
      } else {
        this.height = 400;
        this.tmpWidth = window.innerWidth / 3;
        this.$emit("resizecard", null);
      }
    },
    getItems() {
      this.chartLoading = true;

      //teams
      this.series = []; 
      this.chartCategories = [];
      this.slaughter_fire_ratio = [];
      this.order_amounts = []; 

      let filters = this.filters;
      this.$set(
        filters,
        "start_date",
        moment(this.startDate).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.endDate).format(LARAVEL_DATE_FORMAT)
      );
      console.log(this.filters)
      
      this.$store
        .dispatch(GET_ITEMS, {
          filters: filters,
          url: "api/report/fire-bar-chart-report",
        })
        .then((result) => {
          this.chartLoading = false;
          if (result.status) {
            let tmpData = result.data;
            let self = this;

             if (result.status) {
              this.chartItems = result.data; 
               
              this.chartItems.forEach((chartData, key) => {
                let slaughter_ratio= parseFloat(chartData.slaughter_fire_ratio)
                let order_amount = Number(chartData.order_amount) 
                self.chartCategories.push(chartData.team_name);
                self.slaughter_fire_ratio.push(slaughter_ratio)
                self.order_amounts.push(order_amount);
              }); 
              
              this.series = [
                {
                  name: "Sipariş Toplamı",
                  data: this.order_amounts,
                  color: "#2BAA1E",
                },
                {
                  name: "Kesim Oranı",
                  data: this.slaughter_fire_ratio,
                  color: "#6563A4",
                },
              ];           
            } else {
              let tmpresult = result.data.response.data;
              this.sweetAlertError(tmpresult.message);
            }
          }
        });
    },
    updateColors() {
      if(this.series[0]){
        const colors = this.series[0].data.map(value => (value >= 0 ? '#00ff00' : '#ff0000'));
        this.chartOptions.plotOptions.bar.colors.ranges = colors.map((color, index) => ({
          from: index,
          to: index,
          color: color,
        }));
      }
    },
  },
  watch: {
    onClickButton: function (newVal, oldVal) {
      if (newVal == true) {
        if (this.chartLoading != true) {
          this.series = [];
          this.Company = [];
          this.getItems();
        }
      }
    },
  },
};
</script>
<style scoped>
.second-quality-title {
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  margin-top: 5px;
  color: #5cb7e0;
  justify-content: space-between;
}
.graphic-container {
  overflow-x: scroll;
  display: flex;
  position: relative;
}
.button-open-close {
  border: 0px !important;
  width: 20px;
}
.loading-area {
  height: 410px;
}
.apexcharts-canvas{
  max-width:1000px !important
}
</style>
