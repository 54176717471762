<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="row py-3 px-4">
        <div class="col-lg-12 col-xl-12 px-0">
          <form @submit.prevent="filterResult()">
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="team_ids"
                  :not-list="false"
                  :options="teamList"
                  :title="$t('scope.Team') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  :isOpenMultiselect="true"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="company_ids"
                  :not-list="false"
                  :options="customerList"
                  :title="$t('order.customer') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_company_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="represantative_ids"
                  :not-list="false"
                  :options="represantativeList"
                  :title="$t('Müşteri Temsilcisi') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="represantative_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="supplier_ids"
                  :not-list="false"
                  :options="supplierList"
                  :title="$t('Üretici') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="supplier_id"
                ></custom-multi-select>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6">
                <custom-multi-select
                  :is-inline="false"
                  :model.sync="order_ids"
                  :not-list="false"
                  :options="orderList"
                  :title="$t('report.order_no') + ':'"
                  :required="false"
                  :showInvalid="false"
                  :hasBottomMargin="false"
                  input-width="200px"
                  name="order_ids"
                ></custom-multi-select>
              </div>
            </div>
            <div class="row align-items-end py-0 my-0 mx-0" v-if="filters">
              <div class="col-lg-4 col-md-6 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.start_date')"
                    :model.sync="filters.start_date"
                    name="start_time"
                    title="general.start_date"
                  >
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                    :helper-text="$t('general.end_date')"
                    :model.sync="filters.end_date"
                    name="end_date"
                    title="general.end_date"
                  >
                  </date-filter>
                </div>
              </div>
              <div
                class="col-xl-1 col-lg-1 col-md-1 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                    @click.prevent="filterResult()"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                    @click="resetFilters"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-12">{{ resizeChartValue }}
      <div class="row">
        <div
          :class="[resizeChartValue == 'piechart' ? 'col-12' : 'col-6']" 
          v-if="resizeChartValue != 'linechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <FireReportPieChart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :onClickButton="onClickButton"
                :company-id="filters.company_id" 
                :order-id="filters.order_ids"
                :user-id="filters.user_id"
                :pie-chart-series="pieChartSeries"
                @resizecard="resizeCard"
              ></FireReportPieChart>
            </template>
          </dashboard-box>
        </div> 
        <div
          :class="[resizeChartValue == 'linechart' ? 'col-12' : 'col-6']"
          v-if="resizeChartValue != 'piechart'"
        >
          <dashboard-box :title="$t('report.endorsemet_report')">
            <template v-slot:preview>
              <FireReportBarChart
                :start-date.sync="filters.start_date"
                :end-date.sync="filters.end_date"
                :onClickButton="onClickButton" 
                :filters="filters" 
                @resizecard="resizeCard"
              ></FireReportBarChart>
            </template>
          </dashboard-box>
        </div> 
      </div>
      <dashboard-box :title="$t('esc.esc_list')">
        <template v-slot:preview>
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="fields"
              :hover="false"
              :items="formattedReport"
              :no-border-collapse="true"
              :outlined="false"
              :page.sync="page"
              :perPage.sync="perPage"
              :striped="true"
              :infiniteId="infiniteId"
              tableVariant="''"
              @hitBottom="onHitBottom"
              :sortBy.sync="order"
              :sortDesc.sync="sort" 
              :responsive="true"
              @onClickView="onClickView" 
            ></data-table>
          </div>
        </template>
      </dashboard-box>      
    </div>
    <b-modal id="select-order-list-modal" ref="select-order-list-modal" hide-header hide-header-close
      scrollable size="lg"> 
          <div @contextmenu.prevent="onContextMenu">
            <data-table
              :bordered="false"
              :borderless="true"
              :fields.sync="orderFields"
              :hover="false"
              :items="fire_order_list"
              :no-border-collapse="true"
              :outlined="false"
              :striped="true"
              :disabled-infinite-loading="false"
              :infiniteId="infiniteId" 
              tableVariant="''"
              :page.sync="page"
              :perPage.sync="perPage" 
              :sortBy.sync="order"
              :sortDesc.sync="sort"
              :responsive="true" 
              :sticky-header="true"
            ></data-table> 


          </div> 
      <template #modal-footer>
        <div class="col-12 text-center">
          <button class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeSelectCompanyModal">
            {{ $t('general.close') }}
          </button>
        </div>
      </template>
    </b-modal> 
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import TopWaybillNav from "@/view/pages/waybill/layouts/index/TopWaybillNav";
import SecondWaybillNav from "@/view/pages/waybill/layouts/index/SecondWaybillNav";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
// import FireReportChart from "./FireReportChart";
import FireReportPieChart from "./FireReportPieChart";
import FireReportBarChart from "./FireReportBarChart";

import {
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
  ITEMS,
  SET_FILTER,
  FILTER,
} from "@/core/services/store/report/fireReport.module";

import { GET_ITEMS } from "@/core/services/store/REST.module";
import {
  LARAVEL_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
  MOMENT_SYSTEM_DATE_TIME_FORMAT,
} from "@/core/config/constant";
import * as _ from "lodash";
import ContextMenu from "@/assets/components/contextMenu/ContextMenu";
import {
  LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
  LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
} from "@/core/storage/storage-names";

export default {
  name: "fireReport",
  components: {
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    TopWaybillNav,
    SecondWaybillNav,
    DateFilter,
    CustomMultiSelect,
    ContextMenu,
    // FireReportChart,
    FireReportPieChart,
    FireReportBarChart
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      filter: FILTER,
      items: ITEMS,
    }),
    filters: {
      get: function () {
        return this.filter;
      },
      set: function (value) {
        this.setFilter(value);
      },
    },
    onClickResizeControl() {
      if (this.resizeBool != true) {
        return this.resize();
      } else {
        return;
      }
    },
    formattedReport() { 
      if (!this.items || this.items.data === null || this.items.data === undefined)
        return [];
      let temp = []; 
      this.items.data.forEach((item) => {  
        temp.push({
            company_id: item.company_id,
            company_name: item.company_name,
            total_slaughter:  this.numberFormat(item.total_slaughter,0),
            total_loading: this.numberFormat(item.total_loading,0),
            total_order_amount: this.numberFormat(item.total_order_amount,0),
            slaughter_fire_ratio: item.slaughter_fire_ratio,
            slaughter_fire_ratio_color: item.slaughter_fire_ratio_color,
            overload_loading_amount: this.numberFormat(item.overload_loading_amount,0),
            order_fire_ratio: item.order_fire_ratio,
            manifacture_fire_amount: this.numberFormat(item.manifacture_fire_amount,0),
            manifacture_fire_ratio: item.manifacture_fire_ratio,
        });
      });

      let altinda = 0;
      let ortada = 0;
      let ustunde = 0;
      this.items.data.forEach((item) => { 
        if(item.slaughter_fire_value == 1){ 
          altinda+=  parseInt(item.total_order_amount); 
        }else if(item.slaughter_fire_value == 2){
          ortada+= parseInt(item.total_order_amount);
        }else if(item.slaughter_fire_value == 3){
          ustunde+= parseInt(item.total_order_amount);
        }else{
          ustunde+= parseInt(item.total_order_amount);
        }
      }) 
      this.pieChartSeries = [ altinda,ortada,ustunde ] 
      
      return temp;
    },
  },
  data() {
    return {
      // Filters & pagination
      infiniteId: 1,
      sort: "desc",
      order: "id",
      userOptions: [],
      search: null,
      paginate: [],
      orderSizeAndColorExportDateBodySizes: [],
      page: 1,
      perPage: 25,
      currentStoreType: 99999999,
      startDate: moment().subtract(30, "d"),
      endDate: moment().add(1, "d"),
      download: false,
      reportListUrl: "api/report/fire-report", 
      companies: [],
      orderList: [],
      tmpOrderData: [],
      tmpPackingListData: [],
      supplierCompanyOptions: [],
      moment: moment,
      onClickButton: false, 
      fields: [
        {
          key: "company_name",
          label: this.$t("report.customer"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_order_amount",
          label: this.$t("Sipariş Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_loading",
          label: this.$t("Yükleme Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_slaughter",
          label: this.$t("Kesim Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "slaughter_fire_ratio",
          label: this.$t("Kesim Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "overload_loading_amount",
          label: this.$t("Fazla Yükleme Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_fire_ratio",
          label: this.$t("Sipariş Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manifacture_fire_amount",
          label: this.$t("İmalat Fire Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manifacture_fire_ratio",
          label: this.$t("İmalat Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "show_order_detail",
          label: this.$t("Show"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],      
      orderFields:[
        {
          key: "order_number",
          label: this.$t("Sipariş No"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_order_amount",
          label: this.$t("Sipariş Adet"),
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_loading",
          label: this.$t("Yükleme Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "total_slaughter",
          label: this.$t("Kesim Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "slaughter_fire_ratio",
          label: this.$t("Kesim Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "overload_loading_amount",
          label: this.$t("Fazla Yükleme Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "order_fire_ratio",
          label: this.$t("Sipariş Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manifacture_fire_amount",
          label: this.$t("İmalat Fire Adeti"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
        {
          key: "manifacture_fire_ratio",
          label: this.$t("İmalat Fire Oranı"),
          sortable: false,
          class: "text-center border border-top-0 border-right-0 font-size-lg",
        },
      ],
      represantative_ids:[],
      order_ids : [],
      supplier_ids:[],
      team_ids:[],
      teamList:[],
      company_ids:[],
      customerList: [],
      represantativeList:[],
      supplierList:[],
      fire_order_list:[],
      pieChartSeries: [], 
      resizeChartValue:null 
    };
  },
  methods: {
    ...mapMutations({
      setFilter: SET_FILTER,
    }),
    resizeCard(val) {
      this.resizeChartValue = val;
    },
    numberFormat(value,digit){
      return Number(value).toLocaleString(
          "tr-TR",
          {
            minimumFractionDigits: digit,
            maximumFractionDigits: digit,
          }
        );
    },
    beforeFilterResult() {
      setTimeout(() => {
        this.filterResult();
      }, 100);
    }, 
    onClickView(payload) { 
      console.log(payload)
      this.getFireOrderListItems(payload);
      this.openSelectOrdersModal()
      
    },
    openSelectOrdersModal() {
      this.$refs['select-order-list-modal'].show();
    },
    closeSelectCompanyModal() {
      this.fire_order_list = [];
      this.$refs['select-order-list-modal'].hide();
    }, 
    getFireOrderListItems(company_id) {
      let filter_list=[]; 
      filter_list = this.filters

      this.$set(
        filter_list,
        'company_id',
        company_id
      ); 
      this.$set(
        filter_list,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filter_list,
        "end_date",
        moment(filter_list.end_date).format(LARAVEL_DATE_FORMAT)
      );  
      this.$store
        .dispatch(GET_ITEMS, {
          filters: filter_list,
          url: "api/report/fire-order-list",
        })
        .then((result) => {       
          let temp = [];
          result.data.forEach((item) => {   
            temp.push({
              order_number: item.order_number ?? '', 
              total_slaughter:  this.numberFormat(item.total_slaughter,0),
              total_loading: this.numberFormat(item.total_loading,0),
              total_order_amount: this.numberFormat(item.total_order_amount,0),
              slaughter_fire_ratio: item.slaughter_fire_ratio,
              slaughter_fire_ratio_color: item.slaughter_fire_ratio_color,
              overload_loading_amount: this.numberFormat(item.overload_loading_amount,0),
              order_fire_ratio: item.order_fire_ratio,
              manifacture_fire_amount: this.numberFormat(item.manifacture_fire_amount,0),
              manifacture_fire_ratio: item.manifacture_fire_ratio,
            });
          });
          this.fire_order_list = temp
        });
    },
    filterResult() { 
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME,
        moment(this.filters.start_date)
      );
      localStorage.setItem(
        LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME,
        moment(this.filters.end_date)
      );
      let self = this;
      this.page = 1;
      this.filters.page = 1;
      this.infiniteId++;
      this.onClickButton = true;
      this.onHitBottom();
    },
    onHitBottom($state = null) { 
      if (this.loading) {
        return;
      }
      let self = this;
      let filters = _.cloneDeep(this.filters);
      filters.company_id = null,
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(filters, "order", this.order);
      this.$set(filters, "sort", this.sort);
      this.$set(filters, "order_ids", JSON.stringify(this.order_ids)); 

      if (this.team_ids){
        this.$set(filters, "team_ids", JSON.stringify(this.team_ids));
      }
      if (this.supplier_ids) {
        this.$set(filters, "supplier_ids", JSON.stringify(this.supplier_ids));
      } 
      if (this.company_ids) {
        this.$set(filters, "company_ids", JSON.stringify(this.company_ids));
      }

      if (this.represantative_ids) {
        this.$set(filters, "represantative_ids", JSON.stringify(this.represantative_ids));
      }

      this.filters = filters
      self.$store
        .dispatch(HANDLE_INFINITE_SCROLL, {
          url: self.reportListUrl,
          filters: filters,
          $state: $state,
        })
        .then((result) => {
          this.onClickButton = false;
          if (result.status) {
            if ($state) {
              this.infiniteId++;
              $state.loaded();
            }
          } else {
            if ($state) {
              $state.complete();
            }
          }
        });
    },
    onContextMenu(event) {
      event.preventDefault();
    },
    resetFilters() {
        (this.sort = "desc"),
        (this.order = "id"),
        this.setFilter({
          page: 1,
          per_page: 25,
          search: "",
          start_date: moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT),
          end_date: moment().add(1, "d").format(LARAVEL_DATE_FORMAT),
        });
        this.team_ids = []
        this.supplier_ids = []
        this.company_ids = []
        this.represantative_ids = []
        this.order_ids = [] 
    },
    setup() {
      let self = this,
      promises = []; 
      
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/team-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, { 
          url: "api/companies",
        })
      ); 
      
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/representative-list",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?",
        })
      );

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/supplier-list",
        })
      ); 

      Promise.all(promises).then((results) => {
        if (results[0].status) { 
          self.teamList = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "user_name"
          );
        }

        if (results[1].status) {
          self.customerList = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "name"
          );
        }
         
        if (results[2].status) {
          self.represantativeList = self.convertArrayToObjectByKey(
            results[2].data,
            "id",
            "user_name"
          );
        }

        if (results[3].status) {
          this.orderList = results[3].data;
        }
       
        if (results[4].status) {
          self.supplierList = self.convertArrayToObjectByKey(
            results[4].data,
            "id",
            "name"
          );
        }

      });
    },
    orderFilter(param){
      let params=''; 
      if(param.team_ids){
        params += '&team_id='+JSON.stringify(param.team_ids);
      }
      if(param.company_ids){
        params += '&company_id='+JSON.stringify(param.company_ids);
      } 
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/report/order-list-packing?"+params, 
        })
        .then((result) => { 
          if (result.status) {
            this.orderList = result.data
          } else {
            console.log({ error: result });
          }
        });
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("Fire Raporu") },
    ]);

    if (!this.isUserGranted("EscTable", ["viewAny"])) {
      return false;
    }

    if (!this.filter) {
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_END_DATE_STORAGE_NAME))
      : moment().add(1, "d").format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(
      LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME
    )
      ? moment(localStorage.getItem(LOADING_DEADLINE_REPORT_START_DATE_STORAGE_NAME))
      : moment().subtract(30, "d").format(LARAVEL_DATE_FORMAT);

    this.setup();
    this.onClickButton = true;
    setTimeout(this.filterResult, 500);
  },
  watch: {
    sort() {
      this.beforeFilterResult();
    },
    order() {
      this.beforeFilterResult();
    },
    "team_ids"(value){ 
      this.orderFilter({'team_ids':value})      
    },
    "company_ids"(value){ 
      this.orderFilter({'company_ids':value})      
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  background-color: white !important;
}
.b-table-sticky-header {
  max-height: 500px;
}
.b-table-sticky-header > .table.b-table > tbody > tr > th {
  background-color: white !important;
}
</style>
