<template>
    <div>
      <p class="endorsement-title">{{ $t("Fire Oranı") }}</p>
      <apexchart
        type="pie"
        height="400"
        :options="chartOptions"
        :series="pieChartSeries"
      ></apexchart>
    </div>
  </template>
  <script>
  import { GET_ITEMS, LOADING } from "@/core/services/store/REST.module";
  import VueApexCharts from "vue-apexcharts";
  import { mapGetters } from "vuex";
  import { LARAVEL_DATE_FORMAT } from "@/core/config/constant";
  import * as _ from "lodash";
  
  export default {
    name: "FireReportPieChart",
    computed: {
      ...mapGetters({
        loading: LOADING,
      }),
    },
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        chartCategories: [],  
        tmpSeries: [],
        series: [],
        labels: [],
      };
    },  
    props: [
      "startDate",
      "endDate", 
      "companyIds",
      "orderIds",
      "onClickButton", ,
      "pieChartSeries"
    ],
    mounted() { 
      this.getItems();
    },
    computed: {
      chartOptions() {
        let chartOptions = {
          chart: {
            type: "pie",
          },
          labels: ["<2%", ">2% - <3%", ">3%"],
          responsive: [
            {
              options: {
                chart: {
                  width: "auto",
                  height: "auto",
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          tooltip: {
            y: {
              formatter: function (value) {
                return value.toLocaleString("tr-TR") + " Adet";
              },
            },
          },
        };
        return chartOptions;
      },
    },  
    methods: {
      getItems() {
        this.pieChartSeries  
      },
    },
    watch: {
      onClickButton: function (newVal, oldVal) {
        if (newVal == true) {
          this.series = [];
          this.labels = [];
          this.getItems();
        }
      },
    },
  };
  </script>
  <style scoped>
  .endorsement-title {
    font-size: 14px;
    font-weight: 800;
    text-align: center;
    margin-top: 5px;
    color: #5cb7e0;
  }
  </style>
  